import { default as ai_45form_45builderOUWWlEtidiMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editxOprNBABnyMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexHRndpOpOF6Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexfpdyHdSnHvMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexdy5BlfHXsUMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharesNI7YslUQGMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsa66YpC6WuUMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionskrEiDY6tt8Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showMBTJPKma3fMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestPk2e9cpki7Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexENf9VTssDCMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeEOS69Ke03CMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexBH19PFiEGDMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginUqqTve8ktWMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callback3TPfkRVfORMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailb9mjf3jTjCMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93z5svijy3skMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingljH88dlFn0Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyZdFD9sqFeUMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerCNgygtaQAWMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensNUGYiYqNCOMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountJAYeMLc1O7Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminPI0ErOlMCaMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingL3JA98bS73Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsQc8rLysTtUMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexidA10DFGBAMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordtxEvBXIbMmMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profile9YxQSQYeqlMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspacePkxj4909l7Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsGM7IZ1IXgWMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as error4RfIUwBOAgMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successxNdG0BNBX4Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93zHCDfuV1ogMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexzkXjxbHgY2Meta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93M78XCzL57qMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatessqVWdVBvtYMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_932hHcFThCmGMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsgASsr6tkWCMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsGrSMEiGxLjMeta } from "/codebuild/output/src1177454017/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderOUWWlEtidiMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editxOprNBABnyMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showMBTJPKma3fMeta?.name,
    path: "/forms/:slug()/show",
    meta: showMBTJPKma3fMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexfpdyHdSnHvMeta?.redirect,
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexdy5BlfHXsUMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharesNI7YslUQGMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsa66YpC6WuUMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionskrEiDY6tt8Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestPk2e9cpki7Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexENf9VTssDCMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeEOS69Ke03CMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginUqqTve8ktWMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailb9mjf3jTjCMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93z5svijy3skMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingljH88dlFn0Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyZdFD9sqFeUMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerCNgygtaQAWMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsGM7IZ1IXgWMeta?.name,
    path: "/settings",
    meta: settingsGM7IZ1IXgWMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountJAYeMLc1O7Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminPI0ErOlMCaMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingL3JA98bS73Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsQc8rLysTtUMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexidA10DFGBAMeta?.redirect,
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordtxEvBXIbMmMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profile9YxQSQYeqlMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspacePkxj4909l7Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error4RfIUwBOAgMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successxNdG0BNBX4Meta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatessqVWdVBvtYMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsgASsr6tkWCMeta || {},
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src1177454017/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]